import { css } from 'styled-components'

interface ITheme {
  colors: {
    dark: string
    primary: string
    secondary: string
    tertiary: string
    [key: string]: string
  }
}

export const theme: ITheme = {
  colors: {
    dark: '#2e3280',
    light: '#fff',
    primary: '#C33F83',
    purple: '#5b63fe',
    secondary: '#FF6F00',
    tertiary: '#2EC4B6',
  },
}

export const yPad = css`
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;

  @media (min-width: 600px) {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  @media (min-width: 940px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @media (min-width: 1180px) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
`

export const yMargin = css`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  @media (min-width: 600px) {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  @media (min-width: 940px) {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  @media (min-width: 1180px) {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

export const xPad = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (min-width: 600px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @media (min-width: 940px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

export const xMargin = css`
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  @media (min-width: 600px) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @media (min-width: 940px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }
`

export default css`
  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
    vertical-align: baseline;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  [hidden],
  template {
    display: none;
  }
  a {
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  abbr[title] {
    border-bottom: 1px dotted;
  }
  b,
  strong {
    font-weight: bold;
  }
  dfn {
    font-style: italic;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  small {
    font-size: 0.85em;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  figure {
    margin: 0;
  }
  hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }
  pre {
    overflow: auto;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }
  button {
    background: none;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: inherit;
    font-size: inherit;
    line-height: normal;
    outline: 0;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  button[disabled],
  html input[disabled] {
    cursor: default;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input {
    line-height: normal;
  }
  input[type='checkbox'],
  input[type='radio'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  input[type='search'] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
  legend {
    border: 0;
    padding: 0;
  }
  textarea {
    overflow: auto;
  }
  optgroup {
    font-weight: bold;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
  }
  input,
  select,
  textarea {
    color: inherit;
    font: inherit;
    line-height: normal;
    background-color: transparent;
    outline: 0;
    border: 0;
    border-radius: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    resize: vertical;
    padding: 0;
  }
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    vertical-align: top;
    -ms-interpolation-mode: bicubic;
  }
  html {
    font-size: 100%;
  }
  body {
    font-family: 'Colfax', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #3d4677;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
  }
  @media screen and (min-width: 940px) {
    body {
      font-size: 16px;
    }
  }
  @media (min-width: 940px) {
    body {
      line-height: 1.65;
    }
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-weight: 700;
    color: #2e3280;
    line-height: 1.25;
    letter-spacing: -0.03em;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  ul,
  ol {
    margin: 0 0 0.75em 0;
  }
  h1:last-child,
  .h1:last-child,
  h2:last-child,
  .h2:last-child,
  h3:last-child,
  .h3:last-child,
  h4:last-child,
  .h4:last-child,
  h5:last-child,
  .h5:last-child,
  h6:last-child,
  .h6:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
  p,
  ul,
  ol {
    margin-bottom: 1.25em;
  }
  @media (min-width: 940px) {
    p,
    ul,
    ol {
      margin-bottom: 1.5em;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  strong {
    font-weight: 700;
    color: #2e3280;
  }
  h1,
  .h1 {
    margin-bottom: 0.15em;
    line-height: 1.15;
    font-size: 2rem;
  }
  @media screen and (min-width: 600px) {
    h1,
    .h1 {
      font-size: 2.4rem;
    }
  }
  @media screen and (min-width: 940px) {
    h1,
    .h1 {
      font-size: 2.8rem;
    }
  }
  @media screen and (min-width: 1180px) {
    h1,
    .h1 {
      font-size: 3.2rem;
    }
  }
  @media screen and (min-width: 1440px) {
    h1,
    .h1 {
      font-size: 3.5rem;
    }
  }
  h2,
  .h2 {
    margin-bottom: 0.25em;
    font-size: 1.8rem;
  }
  @media screen and (min-width: 940px) {
    h2,
    .h2 {
      font-size: 2.1rem;
    }
  }
  @media screen and (min-width: 1180px) {
    h2,
    .h2 {
      font-size: 2.4rem;
    }
  }
  @media screen and (min-width: 1440px) {
    h2,
    .h2 {
      font-size: 3rem;
    }
  }
  h3,
  .h3 {
    font-size: 1.4rem;
  }
  @media screen and (min-width: 600px) {
    h3,
    .h3 {
      font-size: 1.6rem;
    }
  }
  @media screen and (min-width: 1180px) {
    h3,
    .h3 {
      font-size: 1.8rem;
    }
  }
  @media screen and (min-width: 1440px) {
    h3,
    .h3 {
      font-size: 2rem;
    }
  }
  h4,
  .h4 {
    font-size: 1.25rem;
  }
  @media screen and (min-width: 940px) {
    h4,
    .h4 {
      font-size: 1.3rem;
    }
  }
  @media screen and (min-width: 1180px) {
    h4,
    .h4 {
      font-size: 1.4rem;
    }
  }
  @media screen and (min-width: 1440px) {
    h4,
    .h4 {
      font-size: 1.5rem;
    }
  }
  h5,
  .h5 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 13px;
  }
  @media (min-width: 940px) {
    h5,
    .h5 {
      font-size: 14px;
    }
  }

  p {
    text-align: justify;
  }
`
