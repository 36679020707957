export default [
  {
    links: [
      {
        text: 'About',
        to: '/about',
      },
      {
        text: 'FAQs',
        to: '/faqs',
      },
      {
        text: 'Contact',
        to: '/contact',
      },
    ],
    text: 'Company',
  },
  {
    links: [
      {
        text: 'Breast Augmentation',
        to: '/procedures/breast-augmentation',
      },
      {
        text: 'Breast Reduction',
        to: '/procedures/breast-reduction',
      },
      {
        text: 'Breast Reconstruction',
        to: '/procedures/breast-reconstruction',
      },
      {
        text: 'Abdominoplasty (Tummy Tuck)',
        to: '/procedures/tummy-tuck',
      },
      // {
      //   text: 'Liposuction',
      //   to: '/procedures/liposuction',
      // },
      {
        text: 'Skin Cancer Surgery',
        to: '/procedures/skin-cancer-surgery',
      },
      {
        text: 'Scar Revision',
        to: '/procedures/scar-revision',
      },
      {
        text: 'Mole Removal',
        to: '/procedures/mole-removal',
      },
      // {
      //   text: 'Facelift',
      //   to: '/procedures/facelift',
      // },
      {
        text: 'Nose Surgery',
        to: '/procedures/nose-surgery',
      },
      {
        text: 'Ear Correction',
        to: '/procedures/prominent-ear-correction',
      },
      {
        text: 'Earlobe Repair',
        to: '/procedures/earlobe-repair',
      },
      {
        text: 'Eyelid Surgery',
        to: '/procedures/eyelid-surgery',
      },
    ],
    text: 'Procedures',
  },
  {
    links: [
      {
        text: 'Treatments for Wrinkles and Volume Loss',
        to: '/non-surgical/dermal-fillers',
      },
      // {
      //   text: 'CoolSculpting',
      //   to: '/non-surgical/coolsculpting',
      // },
      {
        text: 'Excess Underarm Sweating',
        to: '/non-surgical/excess-underarm-sweating',
      },
      // {
      //   text: 'IPL & Laser Treatments',
      //   to: '/non-surgical/ipl-and-laser-treatments'
      // },
      {
        text: 'Skin Care Solutions',
        to: '/non-surgical/skin-treatments',
      },
    ],
    text: 'Non-Surgical',
  },
  {
    links: [
      {
        text: 'Contact Us',
        to: '/contact',
      },
      {
        text: 'Patient Information',
        to: '/patient-information',
      },
      {
        text: '02 9480 8577',
      },
    ],
    text: 'Support',
  },
]
