export default [
  {
    text: 'Home',
    to: '/',
  },
  {
    dropdown: [
      {
        links: [
          {
            text: 'Breast Augmentation',
            to: '/procedures/breast-augmentation',
          },
          {
            text: 'Breast Reduction',
            to: '/procedures/breast-reduction',
          },
          {
            text: 'Breast Reconstruction',
            to: '/procedures/breast-reconstruction',
          },
        ],
        text: 'Breasts',
      },
      {
        links: [
          {
            text: 'Abdominoplasty',
            to: '/procedures/tummy-tuck',
          },
          // {
          //   text: 'Liposuction',
          //   to: '/procedures/liposuction',
          // },
          {
            text: 'Skin Cancer Surgery',
            to: '/procedures/skin-cancer-surgery',
          },
          {
            text: 'Scar Revision',
            to: '/procedures/scar-revision',
          },
          {
            text: 'Mole Removal',
            to: '/procedures/mole-removal',
          },
        ],
        text: 'Body',
      },
      {
        links: [
          // {
          //   text: 'Facelift',
          //   to: '/procedures/facelift',
          // },
          {
            text: 'Nose Surgery',
            to: '/procedures/nose-surgery',
          },
          {
            text: 'Prominent Ear Correction',
            to: '/procedures/prominent-ear-correction',
          },
          {
            text: 'Earlobe Repair',
            to: '/procedures/earlobe-repair',
          },
          {
            text: 'Eyelid Surgery',
            to: '/procedures/eyelid-surgery',
          },
        ],
        text: 'Face',
      },
    ],
    hasSubSections: true,
    text: 'Plastic Surgery',
  },
  {
    dropdown: [
      {
        text: 'Treatments for Wrinkles and Volume Loss',
        to: '/non-surgical/dermal-fillers',
      },
      // {
      //   text: 'CoolSculpting',
      //   to: '/non-surgical/coolsculpting',
      // },
      {
        text: 'Excess Underarm Sweating',
        to: '/non-surgical/excess-underarm-sweating',
      },
      {
        text: 'Skin Care Solutions',
        to: '/non-surgical/skin-treatments',
      },
    ],
    width: '250px',
    text: 'Non-Surgical',
  },
  {
    dropdown: [
      {
        text: 'Dr Sellars',
        to: '/about',
      },
      {
        text: 'Team',
        to: '/about#team',
      },
      {
        text: 'Media',
        to: '/media',
      },
    ],
    text: 'About',
  },
  {
    text: 'Patient Information',
    to: '/patient-information',
    dropdown: [
      {
        text: 'Information',
        to: '/patient-information',
      },
      {
        text: 'FAQs',
        to: '/faqs',
      },
    ],
  },
  {
    text: 'Interplast',
    to: '/interplast',
  },
  {
    text: 'Gallery',
    to: '/gallery',
  },
  {
    text: 'Contact',
    to: '/contact',
  },
]
