import * as React from 'react'

import {
  SocialMedia,
  Footer as StyledFooter,
  FooterLink,
  FooterList,
  FooterInfo,
  StyledFlex
} from './styles'
import Logo from '../Logo'

import footerLinks from './footer'

import styled from 'styled-components'

const Image = styled.div`
  flex: 27.5%;
  text-align: center;

  img {
    padding: 0 25px;

    @media (max-width: 600px) {
      padding: 0 15px;
    }
  }
`

const Logos = styled.div`
  width: 100vw;
  border-top: 1px solid #eee;
  max-width: 1400px;
  margin: 20px auto;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 980px) {
    flex-wrap: wrap;
    padding: 50px;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`

const Footer: React.SFC = () => (
  <>
  <Logos>
    <Image>
      <img src="/assets/logos/asps.png" />
    </Image>
    <Image>
      <img src="/assets/logos/fracs.jpg" />
    </Image>
    <Image>
      <img src="/assets/logos/asaps.png" />
    </Image>
    <Image>
      <img src="/assets/logos/american-sps.png" />
    </Image>
    <Image>
      <img src="/assets/logos/aus-med.png" />
    </Image>
  </Logos>
  <StyledFooter>
    <FooterInfo>
      <Logo color="primary" />
      <p>
        © 2023 Plastic Surgery Sydney. <br />
        Medical Registration Number MED 0001158703 <br />
        Specialty Surgery - Plastic and Reconstructive Surgery
      </p>
    </FooterInfo>
    <StyledFlex>
      {footerLinks.map(item => (
        <FooterList key={item.text}>
          <span>{item.text}</span>
          {item.links.map(link => (
            <FooterLink key={link.text} to={link.to || '#'}>
              {link.text}
            </FooterLink>
          ))}
        </FooterList>
      ))}
    </StyledFlex>
  </StyledFooter>
  </>
)

export default Footer
