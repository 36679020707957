import Helmet from 'react-helmet'
import * as React from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import Navbar from './Navbar'
import Footer from './Footer'
import baseStyles, { theme } from '../utils/theme'

// tslint:disable-next-line:no-unused-expression
const GlobalStyle = createGlobalStyle`
  ${baseStyles}
`

interface Props {
  bg?: string
  header?: 'light' | 'dark'
  children: React.ReactNode
}

interface IMainProps {
  bg?: string
}

const Main = styled<IMainProps, any>('div')`
  background-color: ${props => props.bg || 'none'};
  position: relative;
  overflow-x: hidden;
`

const MainLayout = ({ children, bg, header }: Props) => (
  <ThemeProvider theme={theme}>
    <Main bg={bg}>
      <Helmet>
        <meta charSet="utf-8" />

        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/assets/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/assets/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/assets/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/assets/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="/assets/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="/assets/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-16x16.png"
          sizes="16x16"
        />

        <meta name="og:type" content="website" />
      </Helmet>
      <Navbar theme={header} />
      {children}
      <Footer />
      <GlobalStyle />
    </Main>
  </ThemeProvider>
)

export default MainLayout
