import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import Button from '../Button'
import Logo from '../Logo'
import { transparentize } from 'polished'

export const NavLogo = styled(Logo)`
  display: block;
  height: 50px;
  width: auto;
  margin-right: 2rem;

  @media (min-width: 600px) {
    height: 64px;
  }
  @media (min-width: 1180px) {
    height: 50px;
  }
`

export const DropdownContainer = styled.div`
  padding: 0 1rem;
  min-width: 190px;
  h5 {
    cursor: default;
    margin-bottom: 1rem;

    @media (max-width: 939px) {
      display: none;
    }
  }
`

interface IDropdownProps {
  flex: boolean
  width: string
}

export const Dropdown = styled<IDropdownProps, any>('div')`
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate3d(0, 0.25rem, 0);
  background-color: ${props => props.theme.colors.primary};
  box-shadow: 0 6px 18px 0 rgba(46, 50, 128, 0.15);
  padding: 1.5rem;
  border-radius: 4px;
  backface-visibility: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  width: ${props => props.width || 'initial'};

  display: ${props => (props.flex ? 'flex' : 'initial')};

  @media (max-width: 939px) {
    opacity: 1;
    background: none;
    visibility: visible;
    position: static;
    display: block;
    box-shadow: none;
    transform: none;
    padding-top: 0.5rem;
    width: 100%;
  }
`

export const DropdownLink = styled(Link)`
  display: block;
  width: 100%;
  color: rgba(255, 255, 255, 0.75);
  transition: color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  & + a {
    margin-top: 0.75rem;
  }

  @media (max-width: 939px) {
    color: #fff;
    opacity: 1;
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: none;

    & + a {
      margin-top: 0.25rem;
    }
  }

  &:hover {
    color: #fff;
  }
`

interface INavThemeProp {
  color: 'light' | 'dark' | 'primary' | 'secondary' | 'tertiary' | 'purple'
}

export const NavList = styled<INavThemeProp, any>('ul')`
  display: inline-flex;
  align-items: center;
  flex: 1;
  padding: 0;
  margin: 0;
  list-style: none;
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'currentColor'};

  @media (min-width: 1180px) {
    justify-content: center;
  }
`

const linkStyles = css`
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  color: currentColor;
  line-height: 1.5rem;
  transition: color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 13px;

  @media (min-width: 940px) {
    font-size: 14px;
  }

  &:hover,
  *:focus {
    color: ${props => props.theme.colors.primary};
  }
`

export const NavLink = styled(Link)`
  ${linkStyles};

  @media (max-width: 939px) {
    font-size: 1.4rem;
    text-transform: none;
    padding: 0.25rem 0;
    color: #fff;
  }
`

export const PhoneNumber = styled.a`
  ${linkStyles};

  @media (max-width: 1140px) and (min-width: 940px) {
    display: none;
  }
`

export const NavListMain = styled(NavList)`
  @media (min-width: 940px) {
    display: inline-flex !important;
  }
  @media (max-width: 939px) {
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: ${props => transparentize(0.1, props.theme.colors.dark)};
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    z-index: 999;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`

interface INavItemProps {
  hasDropdown: boolean
}

export const NavItem = styled<INavItemProps, any>('li')`
  position: relative;
  margin-left: 1.5rem;

  @media (min-width: 500px) {
    &:last-child {
      display: none;
    }
  }

  @media (min-width: 1180px) {
    margin-left: 2rem;
  }

  @media (max-width: 939px) {
    ${props =>
      props.hasDropdown &&
      css`
        > ${NavLink} {
          justify-content: center;
          font-size: 14px;
          text-transform: uppercase;
          opacity: 0.6;
          padding-bottom: 0;

          svg {
            display: none;
          }
        }
      `};
  }

  &:hover ${Dropdown} {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
`

export const MenuIcon = styled.button`
  padding: 0.5rem;
  margin: 0.5rem;

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 24px;
    height: 24px;
    pointer-events: none;

    path {
      fill: currentColor;
    }
  }

  @media (min-width: 939px) {
    display: none;
  }
`

export const CloseIcon = styled.button`
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  z-index: 99;

  @media (min-width: 940px) {
    display: none;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 24px;
    height: 24px;
    pointer-events: none;

    path {
      fill: #fff;
    }
  }
`

export const NavButton = styled(Button)`
  @media (max-width: 599px) {
    display: none;
  }
  @media (max-width: 939px) {
    color: #fff;
    background-color: ${props => props.theme.colors.primary};
  }
`

interface INavProps {
  visible: boolean
}

export const Nav = styled<INavProps, any>('nav')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  width: 100%;
  transition: background-color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 200;

  ${NavListMain} {
    display: ${props => (props.visible ? 'inline-flex' : 'none')};

    &.is-mobile-visible {
      display: inline-flex;
      @media (max-width: 939px) {
          display: block;
      }
    }

    @media (max-width: 939px) {
      display: ${props => (props.visible ? 'block' : 'none')};
      height: 100%;
      overflow:scroll;
      padding: 30px 0 50px 0;
      z-index: 1;
    }
    a {
      justify-content:center;
    }
  }

  &.is-fixed {
    padding: 0.75rem 1.5rem;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.98);
    -webkit-backdrop-filter: blur(3px);
    box-shadow: 0 6px 18px 0 rgba(46, 50, 128, 0.15);

    ${NavListMain}, > ul, > div > ul, ${NavList} {
      color: ${props => props.theme.colors.dark} !important;
    }
    
  }
  @media (min-width: 1180px) {
    padding: 2rem;
  }

  @media (max-width: 390px) {
    padding: 0.5rem;
    > div > ul > li {
      margin-left: 0 !important;
    }
  }
`
